<template>
<div id="Photo">

  <div id="swiper-container">
    <div id="swiper-overlay-container">
      <div class="swiper-overlay">Portfolio</div>
    </div>

    <div id="swiper__top">
      <div class="top-links">
        <div class="top-links-left">
          <div
            class="link"
            v-for="(link, index) in links.top_links_left"
            :key="index"
            @click="navigateTo">
            <div class="link__text">{{ link.label }}</div>
          </div>
        </div>
        <div class="top-links-center">Filipe Sousa</div>
        <div class="top-links-right">
          <div
            class="link"
            v-for="(link, index) in links.top_links_right"
            :key="index"
            @click="navigateTo">
            <div class="link__text">{{ link.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <div id="swiper__main-content">
      <div id="container-general">
        <div class="gallery-wrap wrap-effect">

          <div class="picture">
            <div class="picture-inner"></div>
            <div class="picture-inner-description">
              <div class="picture-inner-description-content">
                Lorem ipsum dolor sit amet.
              </div>
            </div>
          </div>

          <div class="picture">
            <div class="picture-inner"></div>
            <div class="picture-inner-description">
              <div class="picture-inner-description-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit
              </div>
            </div>
          </div>

          <div class="picture">
            <div class="picture-inner"></div>
            <div class="picture-inner-description">
              <div class="picture-inner-description-content">
                Assumenda blanditiis deleniti dolor eius, quo soluta temporibus veritatis.
              </div>
            </div>
          </div>

          <div class="picture">
            <div class="picture-inner"></div>
            <div class="picture-inner-description">
              <div class="picture-inner-description-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit
              </div>
            </div>
          </div>

          <div class="picture">
            <div class="picture-inner"></div>
            <div class="picture-inner-description">
              <div class="picture-inner-description-content">
                Lorem ipsum dolor sit amet, consectetur
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div id="swiper__bottom">
      <div id="swiper__bottom-container">

        <div id="bottom-thumbnails">
          <div class="swiper-bottom-thumbnails">
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
            <div class="NavItemThumb"></div>
          </div>
        </div>

        <div id="bottom-background-overlay"></div>

        <div id="bottom-ellipsis-button">
          <div class="swiper-bottom-thumbnails-tab" @click="toggleBottomThumbnails">
            <div class="thumbnails-tab"></div>
          </div>
        </div>

        <div id="bottom-links">
          <div
            class="link"
            v-for="(link, index) in links.bottom_links_center"
            :key="index"
            @click="navigateTo">
            <div class="link__text">{{ link.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { reactive } from 'vue';

export default {
  name: 'Photo',
  setup () {

    const links = reactive({
      top_links_left: [
        { to: 'floating_modal', label: 'Portraits' },
        { to: 'testing', label: 'Fine Art' },
      ],
      top_links_right: [
        { to: 'time_calculator', label: 'Landscape' },
        { to: 'timeline_horizontal', label: 'Macro' },
      ],
      bottom_links_center: [
        { to: 'time_calculator', label: 'more' },
        { to: 'time_calculator', label: 'disclaimer' },
        { to: 'time_calculator', label: 'about' },
        { to: 'time_calculator', label: 'contact' },
      ],
    });


    function toggleBottomThumbnails () {
      let bottomThumbnails = document.getElementById('bottom-thumbnails');

      let swiperBottom = document.getElementById('swiper__bottom');

      if (bottomThumbnails.style.bottom === '47px') {
        bottomThumbnails.style.bottom = '';
      } else {
        bottomThumbnails.style.bottom = '47px';
      }

      if (swiperBottom.style.height === '80px') {
        swiperBottom.style.height = '';
      } else {
        swiperBottom.style.height = '80px';
      }

    }

    function navigateTo () {


    }

    return {
      links,
      toggleBottomThumbnails,
      navigateTo,
    };
  },
};
</script>

<style lang="stylus" scoped>
#Photo
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  //max-width 1200px
  border-radius 20px
  display flex
  align-items center
  justify-content center
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  background-image linear-gradient(#fff, #f5f5fa)

#swiper-container
  background white
  color white
  position absolute
  overflow hidden
  top 0
  left 0
  bottom 0
  right 0
  display flex
  flex-direction column
  #swiper-overlay-container
    pointer-events none
    animation disappear 3s ease 1s forwards
    position absolute
    top calc(50% - 150px)
    left calc(50% - 250px)
    height 300px
    width 500px
    z-index 1000
    border 2px dashed rgba(255, 255, 255, 0.35)
    border-radius 20px
    .swiper-overlay
      border-radius 15px
      position absolute
      top 7px
      left 7px
      bottom 7px
      right 7px
      background rgba(255, 255, 255, 0.55)
      font 500 32px AvertaStd
      display flex
      align-items center
      justify-content center
      color black
  //
  //
  // Links Top
  #swiper__top
    height 55px
    box-sizing border-box
    transition height 0.5s ease
    .top-links
      color white
      height 100%
      display flex
      align-items center
      > div
        display flex
        align-items center
        justify-content space-evenly
        width 100px
        .link
          font 600 12px Averta
          text-transform uppercase
          letter-spacing 1px
          cursor pointer
          color #7d8c99
      .top-links-left
        flex-grow 1
      .top-links-center
        font 400 15px AvertaStd
        text-transform uppercase
        color #2d2d2d
        max-width 200px
        letter-spacing 1px
        word-spacing 2px
        width 200px
      .top-links-right
        flex-grow 1
    &:hover
      height 80px
  //
  //
  // Main Content
  #swiper__main-content
    position relative
    flex-grow 1
    display flex
    align-items center
    justify-content center
    #container-general
      flex-grow 1
      .gallery-wrap
        position absolute
        z-index 20
        top 0
        left 30px
        bottom 0
        right 30px
        display flex
        flex-direction row
        .picture
          position relative
          transition flex 1s ease
          will-change flex, opacity
          padding 0
          height 100%
          flex 1
          background black
          border-left 3px solid white
          border-right 3px solid white
          &:hover
            z-index 1000
            opacity 1
            flex 3
            .picture-inner
              opacity 1
              .picture-inner-description
                background rgba(0, 0, 0, 0.7)
                height 70px
                color white !important
          &:nth-of-type(1)
            border-radius 20px 0 0 20px
            overflow hidden
            .picture-inner
              border-radius 20px 0 0 20px
              background-image: url('../../public/photo/pic-4.jpg')
          &:nth-of-type(2)
            .picture-inner
              background-image: url('../../public/photo/pic-5.jpg')
          &:nth-of-type(3)
            .picture-inner
              background-image: url('../../public/photo/pic-6.jpg')
          &:nth-of-type(4)
            .picture-inner
              background-image: url('../../public/photo/pic-7.jpg')
          &:nth-of-type(5)
            border-radius 0 20px 20px 0
            overflow hidden
            .picture-inner
              border-radius 0 20px 20px 0
              background-image: url('../../public/photo/pic-8.jpg')
          .picture-inner
            transition opacity 0.5s ease
            cursor pointer
            opacity 0.8
            background-position center
            background-repeat none
            background-size cover
            width 100%
            height 100%
            &:hover + .picture-inner-description
              background rgba(0, 0, 0, 0.7)
              .picture-inner-description-content
                color white !important
          .picture-inner-description
            transition background 1s ease, height 1s ease
            will-change background, height
            position absolute
            z-index 1000
            overflow hidden
            top auto
            right 3px
            bottom 0
            left 3px
            height 50px
            background rgba(0, 0, 0, 0.32)
            padding 10px 20px
            box-sizing border-box
            text-align left
            .picture-inner-description-content
              transition color 1s ease
              will-change color
              font 400 12px Averta, sans-serif;
              color #777
              width 300px
  //
  //
  // Links Bottom
  #swiper__bottom
    position relative
    z-index 1000
    height 55px
    padding 10px
    box-sizing border-box
    transition height 500ms ease
    will-change height
    #swiper__bottom-container
      position absolute
      top auto
      left 0
      bottom 0
      right 0
      height 55px
      #bottom-thumbnails
        transition bottom 500ms ease
        position absolute
        z-index 100
        bottom -36px
        width 100%
        display flex
        justify-content center
        .swiper-bottom-thumbnails
          background white
          width 674px
          padding 5px 5px 0 5px
          display flex
          .NavItemThumb
            cursor pointer
            position relative
            min-width 75px
            height 75px
            margin 4px
            background royalblue
            background-size 100%
            background-position center
            &:nth-child(1)
              background-image url('../../public/photo/_whiskey.webp')
            &:nth-child(2)
              background-image url('../../public/photo/_land.webp')
            &:nth-child(3)
              background-image url('../../public/photo/_matterhorn.webp')
            &:nth-child(4)
              background-image url('../../public/photo/_sunset.webp')
            &:nth-child(5)
              background-image url('../../public/photo/_swiss_flag.webp')
            &:nth-child(6)
              background-image url('../../public/photo/_whiskey.webp')
            &:nth-child(7)
              background-image url('../../public/photo/_aerial.jpeg')
            &:nth-child(8)
              background-image url('../../public/photo/_leaves.webp')
      #bottom-background-overlay
        background white
        position absolute
        top auto
        left 0
        bottom 0
        right 0
        height 55px
        z-index 200
      #bottom-ellipsis-button
        cursor pointer
        position absolute
        top -25px
        left calc(50% - 30px)
        height 50px
        width 60px
        z-index 2000
        .thumbnails-tab
          padding-top 10px
        .thumbnails-tab::before
          content ' . . . '
          position absolute
          z-index 1000
          left 0
          color black
          font 700 20px Averta, sans-serif;
          speak never
          width 100%
          text-align center
          -webkit-font-smoothing antialiased
          -moz-osx-font-smoothing grayscale
        .thumbnails-tab::after
          box-sizing border-box
          content ''
          width 100%
          height 0
          border-style solid
          border-width 0 8px 12px
          border-color transparent
          border-bottom-color white
          position absolute
          bottom 25px
          left 0
      #bottom-links
        position relative
        z-index 300
        height 100%
        width 100%
        display flex
        align-items center
        justify-content space-evenly
        font 500 15px Averta
        .link
          cursor pointer
          color #828e99
          &:nth-child(2)
            margin-right 20%

@keyframes disappear
  0%
    visibility 1
    opacity 1
  100%
    visibility 0
    opacity 0

</style>